/*--------------------------------------------------------------
# 0 - Css Fixes
--------------------------------------------------------------*/

//noinspection CssInvalidPseudoSelector
input[type=time]::-webkit-datetime-edit-ampm-field {
  display: none;
}

body.theme-default a {
  width: fit-content;
}

.nav-item > a.nav-link {
  width: auto;
}

@keyframes toolTipAnimation {
  from {
    opacity: 0.3
  }
  to {
    opacity: 1
  }
}

.cdk-overlay-pane {
  animation: 0.5s ease tooltipAnimation;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
  padding: 4px;
}

ul {
  list-style-type: none;
}

li {
  list-style-type: none;
}

table {
  width: 100%;
}

.mat-tab-group {
  width: 100%;
}

.mat-tab-group:not(.bs-unset) > .mat-tab-body-wrapper:not([class*=mat-elevation-z]) {
  @extend .box-shadow;
}

label {
  font-weight: 600;
}

h5 {
  font-weight: 600;
}
