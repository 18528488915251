// Fuse core library
@use '@angular/material' as mat;
@import 'fuse/scss/core';
@import 'app/app.theme';
@import 'styles/colors';
@import 'styles/formcontrol';
@import 'styles/helperclasses';
@import 'styles/buttons';
@import 'styles/overrides';
@import 'styles/input';

@import '@ng-select/ng-select/themes/default.theme.css';
@import 'styles/bootstrap.min.css';
@import 'dropzone/dist/min/dropzone.min.css';
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import 'quill/dist/quill.snow.css';

// Newly extracted styles
@import "styles/font";
@import "styles/splashscreen";
@import "icons/material-icons/outline/style.css";


/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# 1.0 - Fuse Theme / General Styles / General Elements
    1.1 - User Interface
        1.1.1 - Normalization
        1.1.2 - Cards
2.0 - Normalize
    2.1 - Table
3.0 -  Form Controls
    3.1 -  Button
    3.2 - Badge
    3.3 -  Labels
    3.4 -  Progressbar
    3.5 -  Table
4.0 -  Avatar
5.0 -  Alignments
    5.1 - Text
6.0 -  Typography
7.0 -  Packages
    7.1 - @ng-select now in formcontrols.scss
    7.2 - Tree-Root
    7.3 - Font-Awesome
    7.4 - ngb-timepicker
    7.6 - bootstrap
8.0 - Material
    8.1 - Select
    8.2 - Dialog
    8.3 - Tab Group
    8.4 - Table
    8.5 - Card
    8.6 - Button Group
    8.7 - Checkbox
    8.8 - Buttons
    8.9 - Progress Bar
    8.10 - Tooltip
    8.11 - Paginator
9.0 - Redesign
    9.1 - Human Resource Employee List
    9.2 - Customer
    9.3 - Tickets
    9.4 - Warenwirtschaft-Artikel
    9.5 - Warenwirtschaft-Verkauf
    9.6 - Warenwirtschaft-Lager
10.0 - Info Box
11.0 - Login & Password Reset
12.0 - Animations
*/

/*--------------------------------------------------------------
# 1.0 - Fuse Theme / General Styles / General Elements
--------------------------------------------------------------*/

.overflow-hidden {
  overflow: hidden !important;
}

.hover:hover {
  background-color: #e9ecef !important;
}

.fuse-card {
  max-width: unset;
  transition: all 0.4s ease-in-out;
  border-radius: 4px;

  &:hover {
    transition: all 0.4s ease-in-out;
    box-shadow: 0 8px 8px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }
}

.hover-elevation {
  transition: box-shadow .2s ease;

  &:hover {
    box-shadow: 0 8px 30px rgb(0 0 0 / 12%) !important;
    border: 1px solid transparent !important;
  }
}

.content-headline {
  font-size: 35px;
  color: #fafafa;
  margin: 0;
  padding: 20px 0 0 25px;
  word-break: break-all;
}

.header {
  padding: 20px 25px;
  max-width: 100%;

  .content-headline {
    padding: unset;
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .header-icon {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 35px !important;
    color: #fff !important;
    margin-right: 3rem !important;
  }

  img {
    width: 42px;
    height: 42px;
    filter: brightness(0) invert(1);
  }
}

#header-small {
  display: block;
  width: 100%;
  background-color: #039be5;
  min-height: 6vh;
  padding: 2rem;

  .mat-icon,
  i {
    color: #fff;
  }

  .mat-raised-button {
    .mat-icon {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

@media (max-width: 70em) {
  .content-headline {
    font-size: 3rem;
  }

  .header {
    .header-icon {
      font-size: 2em !important;
    }

    img {
      width: 27px;
    }
  }
}

.user-container {
  padding-left: 8px;

  &.logo-text {
    margin: 0 !important;
    letter-spacing: normal !important;

    .user-name {
      font-size: 15px;
    }

    .job-title {
      font-size: 12px;
      line-height: 0.8;
      font-weight: 300;
    }
  }
}

body.theme-default {
  color: #4a475e !important;
}

.dark-divider {
  background-color: #26282b;
}

.content-spacer {
  padding: 25px;
}

button {
  &:focus {
    outline: none;
  }
}

.progress-bar {
  background-color: #158461 !important;
}

.progress {
  height: 2rem !important;
  font-size: 1rem !important;
}

.rendered-component > * {
  height: 100%;
}


/*--------------------------------------------------------------
## 1.1 - User Interface
--------------------------------------------------------------*/

.navbar-scroll-container {
  background-color: #26282b !important;
}

.fuse-navy-900 {
  background-color: #373a3e !important;
}

footer {
  &.fuse-navy-900 {
    background-color: #f5f5f5 !important;
    color: #26282b !important;
  }
}

.avatar-container {
  &.fuse-navy-700 {
    background-color: #373a3e !important;
  }
}

/*--------------------------------------------------------------
### 1.1.2 - Cards
--------------------------------------------------------------*/

.card-header {
  background-color: #ffffff;
}

/*--------------------------------------------------------------
# 2.0 - Normalize
--------------------------------------------------------------*/

.content-spacer {
  .element-row {
    border-top: solid 1px #dadada !important;
  }
}

.cal-month-view .cal-header {
  font-size: 1.6rem !important;
  font-weight: 300 !important;
  color: #158461;
  background-color: #f5f5f5;
  margin-top: 15px;
}

omt-announcement-host {
  z-index: 99999;
  position: absolute;
  width: 100%;
}

/*--------------------------------------------------------------
## 2.1 - Table
--------------------------------------------------------------*/

table {
  overflow: hidden;
}

/*--------------------------------------------------------------
# 3.0 -  Form Controls
--------------------------------------------------------------*/

.card-widget-title {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
  line-height: 0.1em;
  margin: 20px 0;
}

.card-widget-title span {
  position: relative;
  left: 40px;
  padding-left: 5px;
  padding-right: 5px;
  background: #fff;
  font-size: 1.2em;
  height: 10px;
}

.datetime {
  font-size: 0.8em;
  color: #d9d9d9;
  margin-top: 5px;
}

.activity {
  font-size: 12px;
}

.bigtitle {
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  font-weight: 700;
  margin: 20px 0;
  background-color: #c1ccd1;
  color: #2b3b4c;
  text-align: center;
  border-radius: 2px;
}

.empty-field {
  width: 100px;
  height: 25px;
  background-color: gray;
  border-radius: 2px;
}

/*--------------------------------------------------------------
## 3.1 -  Button
--------------------------------------------------------------*/

.btn-group {
  div:first-child {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }

  div:last-child {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }
}

/*--------------------------------------------------------------
## 3.2 -  Badge
--------------------------------------------------------------*/

.badge {
  font-size: 75%;
  line-height: 1.5;
  font-weight: 600;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  text-align: center;
  white-space: nowrap;
  border-radius: 8px;

  &.badge-xl {
    padding: 0.6rem;
    font-size: 90%;
  }
}

.badge:not([class*="badge-"]) {
  color: #fff;
  background-color: #777;
}

.badge-outline {
  display: inline-block;
  padding: 10px;
  font-size: 0.95em;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

@mixin badge-outline($color) {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0 1px $color inset;
  box-shadow: 0 0 0 1px $color inset;
  color: $color;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: $color;
    color: $white;
  }
}

.badge-outline-success {
  @include badge-outline($green)
}

.badge-outline-warning {
  @include badge-outline($orange)
}

.badge-outline-danger {
  @include badge-outline($red)
}

.badge-outline-info {
  @include badge-outline($cyan)
}

/*--------------------------------------------------------------
## 3.3 -  Labels
--------------------------------------------------------------*/

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;

  &.label-secondary {
    background-color: #b6c2c9;
  }

  &.label-lime {
    background-color: #158461;
  }

  &.label-danger {
    background-color: $red;
  }

  &.label-info {
    background-color: $cyan;
  }

  &.label-success {
    background-color: $green;
  }

  &.label-warning {
    background-color: $orange;
  }
}

/*--------------------------------------------------------------
## 3.4 -  Progressbar
--------------------------------------------------------------*/

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media screen {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

/*--------------------------------------------------------------
## 3.5 -  Table
--------------------------------------------------------------*/

/*-----Table Responsive-----*/
@include media-breakpoint('lt-lg') {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

.table-enlarged {
  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    &:not(:first-of-type) {
      padding: 8px;
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  background: white;
  border-radius: 10px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.box-shadow {
  @extend .mat-elevation-z3;
}

body.theme-default {
  .mat-card:not([class*=mat-elevation-z]) {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }
}


.table-full-page {
  height: calc(100vh - 200px);
  overflow: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

tr {
  .action-buttons {
    visibility: hidden;
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint('lt-lg') {
      visibility: visible;
    }
  }

  i:not(:last-of-type) {
    margin-right: 1rem;
  }
}

.mat-table tr:not(.detail-row):hover {
  background-color: rgb(244, 245, 244) !important;

  .action-buttons {
    visibility: visible;

    i {
      cursor: pointer;
    }
  }
}

.dashed-bottom {
  border-bottom: 1px dashed #cacaca;
}

tr.dashed-bottom {
  border-bottom: 1px dashed #cacaca;
}


tr.dashed-bottom:last-child {
  border-bottom: 1px solid #f9b587;
}

.sales tr.dashed-bottom:last-child {
  border-color: #6fa1e3;
}


tr.dashed-bottom > td.mat-cell {
  border-bottom: none;
}

/*--------------------------------------------------------------
# 4.0 -  Avatar
--------------------------------------------------------------*/

.avatar-small {
  width: 24px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
}

.avatar {
  width: 30px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}

.avatar-big {
  width: 50px;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
}

/*--------------------------------------------------------------
# 5.0 -  Alignments
--------------------------------------------------------------*/

.icon-container {
  transition: all 0.4s ease-in-out;
  text-align: center;
  margin-top: 12px;
  margin-right: 5px;
  border: solid 1px #dadada;

  i {
    margin-left: 3px;
  }

  &:hover {
    transition: all 0.4s ease-in-out;
    background-color: #26282b;
    border: solid 1px #dadada;

    i {
      color: #fafafa;
    }
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

/*--------------------------------------------------------------
## 5.1 -  Text
--------------------------------------------------------------*/

.text-center {
  text-align: center;
}

.stats-icon {
  i {
    opacity: 0.5;
    color: #158461 !important;
  }
}

.stats-title {
  font-size: 1.8rem;
  font-weight: 800;
  text-align: center;
  width: 100%;
}

.stats-number {
  font-size: 3.5rem;
  text-align: center;
}

.stats-desc {
  text-align: center;
}

/*--------------------------------------------------------------
# 6.0 -  Typography
--------------------------------------------------------------*/

.headline {
  font-size: 2rem;
}

.font-bold {
  font-weight: 600;
}

.font-bolder {
  font-weight: 800;
}

/*--------------------------------------------------------------
# 7.0 -  Packages
--------------------------------------------------------------*/

// Bootstrap button group
.btn-group > .btn {
  font-size: 13px;
  padding: 1rem 2rem 1rem 1rem;
}

.background-red-stripe {
  background-image: linear-gradient(45deg, #e7c5ce 2.38%, #ffffff 2.38%, #ffffff 50%, #e7c5ce 50%, #e7c5ce 52.38%, #ffffff 52.38%, #ffffff 100%);
  background-size: 21px 21px;
}

.background-green-stripe {
  background-image: linear-gradient(45deg, #aae6b6 2.38%, #ffffff 2.38%, #ffffff 50%, #aae6b6 50%, #aae6b6 52.38%, #ffffff 52.38%, #ffffff 100%);
  background-size: 21px 21px;
}

// WIP
@import '@angular/material/theming';
@mixin ng-select-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $isdark: map-get($theme, is-dark);

  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  $highlight-color: if($isdark, mat.get-color-from-palette($foreground, text), mat.get-color-from-palette($primary));

  .ng-select,
  .ng-select-container,
  .ng-input > input {
    color: mat.get-color-from-palette($foreground, text) !important;
    font: inherit;
  }
  .ng-placeholder {
    display: none;
  }

  .ng-clear-wrapper,
  .ng-arrow-wrapper {
    height: 1em;
    color: mat.get-color-from-palette($foreground, text, 0.4);
  }
  .ng-clear-wrapper:hover,
  .ng-arrow-wrapper:hover {
    color: mat.get-color-from-palette($foreground, text);
  }
  .ng-select .ng-arrow-wrapper .ng-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    height: 7px !important;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container {
    height: 1em;
  }

  .ng-select.ng-select-multiple {
    .ng-value {
      // WIP
      color: mat.get-color-from-palette($primary, default-contrast);
      background: mat.get-color-from-palette($primary);
      padding: 2px 8px;
      border-radius: 12px;
      margin: 0 4px 2px 0;

      .ng-value-label {
        margin-left: 8px;
      }
    }
  }

  .ng-dropdown-panel {
    @include mat.elevation(4);
    background: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, text) !important;

    .mat-option.ng-option-selected:not(.ng-option-marked):not(:hover) {
      background: mat.get-color-from-palette($background, card);

      &:not(.ng-option-disabled) {
        color: mat.get-color-from-palette($foreground, text);
      }
    }

    // left: 0;
    &.ng-select-bottom {
      top: calc(100% + 0.9em);
    }

    &.ng-select-top {
      bottom: calc(100% + 1.25em);
    }

    &.multiple {
      .ng-option {
        &.selected {
          background: mat.get-color-from-palette($background, card);
        }

        &.marked {
          background: mat.get-color-from-palette($foreground, text, 0.04);
        }
      }
    }

    .ng-dropdown-header {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, text, 0.12);
      padding: 0 16px;
      line-height: 3em;
      min-height: 3em;
    }

    .ng-dropdown-footer {
      border-top: 1px solid mat.get-color-from-palette($foreground, text, 0.12);
      padding: 0 16px;
      line-height: 3em;
      min-height: 3em;
    }

    .ng-dropdown-panel-items {
      .ng-optgroup {
        user-select: none;
        cursor: pointer;
        line-height: 3em;
        height: 3em;
        padding: 0 16px;
        color: mat.get-color-from-palette($foreground, text);
        font-weight: 500;

        &.ng-option-marked {
          background: mat.get-color-from-palette($foreground, text, 0.04);
        }

        &.ng-option-disabled {
          cursor: default;
        }

        &.ng-option-selected {
          background: mat.get-color-from-palette($foreground, text, 0.12);
          color: $highlight-color;
        }
      }

      .ng-option {
        line-height: 3em;
        min-height: 3em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 16px;
        text-decoration: none;
        position: relative;
        color: mat.get-color-from-palette($foreground, text, 0.87);
        text-align: left;

        &.ng-option-marked {
          background: mat.get-color-from-palette($foreground, text, 0.04);
          color: mat.get-color-from-palette($foreground, text, 0.87);
        }

        &.ng-option-selected {
          background: mat.get-color-from-palette($foreground, text, 0.12);
          color: $highlight-color;
        }

        &.ng-option-disabled {
          color: mat.get-color-from-palette($foreground, text, 0.38);
        }

        &.ng-option-child {
          padding-left: 32px;
        }

        .ng-tag-label {
          padding-right: 5px;
          font-size: 80%;
          font-weight: 400;
          color: mat.get-color-from-palette($foreground, text, 0.38);
        }
      }
    }
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container > .ng-input {
  top: 10px;
}

/*--------------------------------------------------------------
## 7.2 - Angular Tree Component
--------------------------------------------------------------*/

.angular-tree-component {
  display: block;
  overflow: hidden;
}

.angular-tree-component .tree-node {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.angular-tree-component .node-content-wrapper {
  width: 100%;
  padding: 0.1em;
  vertical-align: middle;
  line-height: 1.7em;
  font-weight: bold;
}

.angular-tree-component .tree-node-level-1 .node-drop-slot {
  background: transparent;
}

.angular-tree-component .node-content-wrapper-active,
.angular-tree-component .node-content-wrapper.node-content-wrapper-active:hover,
.angular-tree-component .node-content-wrapper-active.node-content-wrapper-focused {
  border-left: 4px solid lightslategray;
  margin-right: 5px;
}

.angular-tree-component .node-content-wrapper-active,
.angular-tree-component .node-content-wrapper.node-content-wrapper-active:hover,
.angular-tree-component .node-content-wrapper-active.node-content-wrapper-focused {
  background: #d6d6d654;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.angular-tree-component .node-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.angular-tree-component .tree-children .node-content-wrapper {
  font-weight: normal;
}

.angular-tree-component .tree-node-checkbox {
  position: relative;
  top: 10px;
  margin-left: 5px;
}

.angular-tree-component .toggle-children-wrapper {
  padding: 0 4px;
}

.angular-tree-component .toggle-children {
  cursor: pointer;
  background-image: none;
  font-family: 'Font Awesome 5 Free', serif !important;
  font-size: 1.5rem;
  font-weight: 900;
  right: 16px;
  padding-left: 20px;
  top: 0;
}

.toggle-children-wrapper-expanded .toggle-children:before {
  content: '\f078';
}

.toggle-children-wrapper-collapsed .toggle-children:before {
  content: '\f054';
}

.toggle-children-wrapper-expanded .toggle-children,
.toggle-children-wrapper-collapsed .toggle-children {
  transform: none !important;
  -webkit-transform: none !important;
}

.angular-tree-component .node-content-wrapper {
  width: 100%;
  padding: 0.7em;
  vertical-align: middle;
  line-height: 2em;
  font-weight: bold;
}

.angular-tree-component .tree-node-level-1 .node-drop-slot {
  background: transparent;
}

.angular-tree-component .tree-children .node-content-wrapper {
  font-weight: normal;
}

/*--------------------------------------------------------------
## 7.3 - Font-Awesome
--------------------------------------------------------------*/

.fa, .fas, .far, .fab, .fad, .fal {
  display: inline-block;
}

.fa,
.fas,
.far {
  font-family: 'Font Awesome 5 Free', serif !important;
  font-size: 1.5rem;
  line-height: 24px;
  cursor: pointer;
}

.fab {
  font-family: 'Font Awesome 5 Brands', serif !important;
  font-size: 1.5rem;
  line-height: 24px;
}

.badge-notify {
  background: $cyan;
  position: relative;
  top: -12px;
  left: -5px;
}

.widget-icon i {
  display: block;
  text-align: center;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 28px;
  border-radius: 5px;
}

.widget-icon-small i {
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  border-radius: 5px;
}

.widget-list-item,
a.widget-list-item {
  display: table;
  width: 100%;
  background: #fff;
  text-decoration: none;
  color: #242a30;
}

.widget-list-item .widget-list-action,
.widget-list-item .widget-list-content,
.widget-list-item .widget-list-media {
  padding: 10px;
  display: table-cell;
  vertical-align: middle;
}

.stat-widget-detail {
  font-family: Mulish, sans-serif;
  color: #4a475e;
  font-size: 16px;

  .heading {
    font-size: 24px;
  }
}

/*--------------------------------------------------------------
## 7.4 - ngb-timepicker
--------------------------------------------------------------*/

ngb-timepicker {
  font-size: inherit !important;
}

/*--------------------------------------------------------------
## 7.5 - CK Editor
--------------------------------------------------------------*/

.ck .ck-balloon-panel {
  z-index: 1030 !important;
}

.ck.ck-editor__editable.ck-rounded-corners {
  border-radius: 4px !important;
}

.ck-editor__editable {
  min-height: 80px;
  border: 1px solid #d3d8de !important;
}

.ck-editor__editable:focus {
  border-color: $cyan !important;
  box-shadow: 0 0 0 0.125rem rgba(127, 213, 213, 0.8) !important;
  outline: 0 !important;

  transition: box-shadow 350ms, 350ms;
}

/*--------------------------------------------------------------
# 8.0 - Material
--------------------------------------------------------------*/

.omt-snackbar {
  max-width: 370px;
}

/*--------------------------------------------------------------
## 8.1 - Select
--------------------------------------------------------------*/

div.mat-form-field-infix {
  @include media-breakpoint('xs') {
    width: 140px;
  }
}

.mat-select .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.2em;
}

.mat-select-panel {
  max-height: 300px !important;
}

/*--------------------------------------------------------------
## 8.2 - Dialog
--------------------------------------------------------------*/

.modal-header {
  align-items: center !important;
}

mat-dialog-container.mat-dialog-container {
  overflow-x: hidden;
  padding: 0;
}

.mat-dialog-container > * {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.mat-dialog-content {
  flex-grow: 1;
}

.mat-dialog-actions, .dialog-footer {
  padding: 7px;
  border-top: 1px solid #f5f5f5;
}

@include media-breakpoint('lt-md') {
  .cdk-overlay-pane {
    max-width: 100vw !important;
  }
}

.close {
  font-size: 2.5rem !important;
}

// you can apply this on a case by case basis by using the "panelClass" attribute in the openDialog method
// ex: panelClass: 'dialog-0-p'

.dialog-0-p {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.reporting-dialog {
  mat-dialog-container {
    padding: 0 !important;
    overflow: hidden;
  }
}

.modal-section {
  padding: 1.5rem 3rem;
}

.modal-actions {
  @include media-breakpoint('xs') {
    margin: 0;
    right: 0;
  }

  position: relative;
  top: 30px;
  right: -25px;
  flex-direction: row;
  display: flex;
  place-content: center flex-end;
  align-items: center;
  margin: -1rem -24px;
}

mat-dialog-content.mat-dialog-content {
  padding: 0;
  margin: 0
}

/*--------------------------------------------------------------
## 8.3 - Tab Group
--------------------------------------------------------------*/

.content-spacer .mat-tab-body-wrapper {
  border-radius: 0 0 10px 10px;

  .table-responsive {
    border-radius: 0 0 10px 10px;
  }
}

.mat-tab-body-content {
  overflow-x: hidden !important;
  padding: 0.5rem;
}

mat-tab-group.box-shadow > .mat-tab-body-wrapper {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.mat-tab-label, .mat-tab-link {
  border-bottom: 2px solid transparent;
  transition: border 0.2s ease-in;
}

.mat-tab-label:hover, .mat-tab-link:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

/*--------------------------------------------------------------
## 8.4 - Table
--------------------------------------------------------------*/
$mat-border-color: rgb(172, 171, 181);
$expandedColor: #3c4252;
$expandedBorderWidth: 6px;

.element-row td {
  border-bottom-width: 0 !important;
}

tr.detail-row {
  height: 0 !important;
}

tr.detail-row td {
  height: 0;
  border-top: 0;
}

.border-6 {
  border-left-width: $expandedBorderWidth !important;
  border-right-width: $expandedBorderWidth !important;
}

.expanded {
  border-left: 0 solid $expandedColor;
  border-right: 0 solid $expandedColor;
}

.element-detail {
  overflow: hidden;
  border-bottom-width: 1px !important;
}

.expanded-row {
  border-left: $expandedBorderWidth solid $expandedColor;
  border-right: $expandedBorderWidth solid $expandedColor;
  background-color: rgb(251, 232, 194) !important;
}

.mat-cell {
  font-size: 12px !important;
  border-bottom-color: $mat-border-color !important;
}

.mat-header-cell {
  border-bottom-color: $mat-border-color !important;
}

.mat-table {
  color: $text-color !important;
}

td > mat-form-field {
  max-width: 100px;
}

td > ng-select {
  max-width: 70%;
}

@include media-breakpoint('lt-lg') {
  td.mat-cell {
    min-width: 80px;
  }
}

/*--------------------------------------------------------------
## 8.5 - Card
--------------------------------------------------------------*/

mat-card {
  &.not-rounded {
    border-radius: unset;
  }

  .headline {
    font-family: Mulish, sans-serif;
    font-size: 24px;
    color: #4a475e;
    margin-bottom: 15px;
  }
}

.mat-card-header-text {
  margin: 0 !important;
}

/*--------------------------------------------------------------
## 8.6 - Button Group
--------------------------------------------------------------*/

//body.theme-default .mat-button-toggle-appearance-standard {
//  background: $black;
//  color: $white;
//}
//
//body.theme-default .mat-button-toggle-appearance-standard i {
//  color: $white;
//}

body.theme-default .mat-button-toggle-group-appearance-standard .mat-button-toggle-checked {
  background-color: #404040;
  color: $white;
}

/*--------------------------------------------------------------
## 8.7 - Checkbox
--------------------------------------------------------------*/

.mat-checkbox-layout {
  white-space: normal !important;
}

/*--------------------------------------------------------------
## 8.8 - Buttons
--------------------------------------------------------------*/

button.rounded {
  background-color: rgb(61, 66, 70) !important;
  color: $white !important;
  min-width: 150px !important;
  padding-left: 4rem !important;
  padding-right: 4rem !important;
  border-radius: 20px !important;

  &:disabled {
    background: #7c7c7c !important;
  }

  &.delete {
    background: rgb(239, 78, 81) !important;
  }

  &.delete:disabled {
    background: #ff8688 !important;
  }
}

.rectangle-button {
  background-color: rgb(61, 66, 70);
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: opacity 0.5s;

  > i {
    width: fit-content;
    height: fit-content;
    line-height: initial;
  }
}

.rectangle-button:hover {
  opacity: 0.9;
}

.rectangle-button.big {
  width: 100px;
  height: 100px;

  > i {
    font-size: 2.4em !important;
    margin-bottom: 0.6rem;
  }
}

button.mat-primary:not(.mat-icon-button, .mat-button) {
  i {
    color: $white;
  }
}

/*--------------------------------------------------------------
## 8.9 - Progress Bar
--------------------------------------------------------------*/

.mat-progress-bar {
  min-height: 6px;
}

/*--------------------------------------------------------------
## 8.10 - Tooltip
--------------------------------------------------------------*/

body.theme-default .mat-tooltip {
  font-size: 14px;
  white-space: pre-line;
}

/*--------------------------------------------------------------
## 8.11 - Paginator
--------------------------------------------------------------*/

body mat-paginator.mat-paginator {
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 1rem;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.1rem;
    border: unset;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    min-height: 34px;
    min-width: 70px;
    align-items: center;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
  }

  .mat-paginator-page-size-select {
    margin: 0 1rem;
  }

}


/*--------------------------------------------------------------
## 9.0 - Redesign
--------------------------------------------------------------*/

.subtraction-toggle {
  height: 18px;
  cursor: pointer;
  padding: 0 14px;
  background-color: gainsboro;
  border-radius: 8px;
  color: #9ea1a9;
  transition: color 0.4s ease-in;
  font-size: 26px;
  line-height: 14px;
}

.subtraction-active {
  color: $red;
}

.warehouse-fa-big {
  opacity: 0.3;
  font-size: 13em !important;
  color: #ed6a1d !important;
  width: auto;
  height: auto;
  line-height: inherit;
}

.mat-form-field.redesign {
  .mat-form-field-outline {

    min-height: 40px;
    border: 0.5px solid #acacb6;
    border-radius: 6px;
  }
}

body.theme-default .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #acacb6;
}

// unset if focused, otherwise the border will be displayed on top of the input label
.mat-form-field.redesign.mat-focused {
  .mat-form-field-outline {
    border: unset;
  }
}

ngb-timepicker > fieldset {
  padding: 0;
}

ngb-timepicker.redesign input {
  min-height: 40px;
  border: 0.5px solid #acacb6;
  border-radius: 4px;
}

input:not(:read-only).redesign:focus, input:not(:read-only).sales-input:focus, input:not(:read-only).form-control:focus,
ng-select.sales-input.ng-select-focused > .ng-select-container, ng-select.ng-select-focused > .ng-select-container,
textarea:not(:read-only).redesign:focus, textarea:not(:read-only).sales-input:focus,
.mat-form-field.form-field-unset.mat-focused {
  &:not(:disabled) {
    @extend .input-focus;
  }
}

$input-hover-shadow: inset 0 0 0 1px #acacb6, inset 0 0 0 1px #acacb6, inset 0 0 0 1px #acacb6;

input.redesign, mat-form-field.redesign, textarea, div.time-input-container {
  transition: 0.2s all ease;

  &:hover {
    box-shadow: $input-hover-shadow;
  }
}

input.redesign {
  min-height: 40px;
  border: 0.5px solid #acacb6;
  border-radius: 4px;
}

.ng-select {
  transition: 0.2s all ease;

  .ng-select-container:hover {
    box-shadow: $input-hover-shadow;
    cursor: pointer;
  }
}

.ng-select:not(.sales-input) {
  .ng-select-container {
    min-height: 40px;
    border: 0.5px solid #acacb6;
    border-radius: 4px;
  }
}

omt-project-dropdown,
omt-customer-dropdown {
  ng-select {
    .ng-select-container {
      min-height: 40px;
      border: 0.5px solid #acacb6 !important;
      border-radius: 4px;
    }
  }
}

textarea.redesign {
  border: 0.5px solid #acacb6;
  border-radius: 4px;
  padding: 8px 16px;
  min-height: 80px;
}

.status {
  padding: 1.7rem 1rem;
  color: #fff;
  max-width: 200px;
  min-width: 120px;
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.2s linear, color 0.2s linear;

  &.status-small {
    padding: 6px;
  }
}

.mat-table .status.status-small {
  padding: 5px 0;
}


.redesign .dropzone, .dropzone.redesign {
  min-height: 140px;
  padding: 10px 10px;
  width: 100%;
  border: 3px dashed #2196f3;
  border-radius: 4px;
  background: #f1f1f1;
  display: flex;
  place-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.redesign .dropzone .dz-message, .dropzone.redesign .dz-message {
  text-align: center;
  margin: 0.5em 0;
  font-size: 1.8rem;
  display: flex;
  place-content: center center;
  align-items: center;
}

.redesign .dropzone i, .dropzone.redesign i {
  font-size: 45px;
  display: inline;
  margin-right: 1rem;
}

.dropzone .dz-button::before {
  content: '\f574';
  position: relative;
  font-family: 'Font Awesome 5 Free' !important;
  font-weight: 900;
  font-size: 32px;
  color: $f-muted;
  top: 5px;
  margin-right: 2rem;
}


$tab-height: 56px;

.redesign .mat-tab-body-content {
  padding: 0 !important;
}

.redesign .mat-tab-header {
  background-color: $white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.redesign .mat-tab-group {
  width: 100%;
  border-radius: 0 0 10px 10px;
}

.redesign .mat-stepper-horizontal {
  margin-top: -$tab-height;
  background-color: unset !important;
}

.redesign .mat-horizontal-content-container {
  padding: unset !important;
}

.redesign .mat-horizontal-stepper-header-container {
  background-color: white;
}

@include media-breakpoint('lt-lg') {
  .redesign .mat-tab-group {
    margin-top: 0;
  }
}

.redesign .mat-tab-label, .redesign .mat-tab-links > .mat-tab-link {
  height: $tab-height !important;
  opacity: 1 !important;
  background-color: $white;
  text-decoration: none !important;

  i {
    color: #4a475e !important;
  }
}

.redesign .mat-tab-label-active {
  background-color: $white;
  color: #000 !important;

  i {
    color: #000 !important;
  }
}

.filter {
  color: #505050 !important;
}

.filter-button {
  background: #fff !important;

  i {
    color: #505050 !important;
    transform: rotate(90deg);
  }
}

/*--------------------------------------------------------------
## 9.1 - Human Resource
--------------------------------------------------------------*/

/*--------------------------------------------------------------
## 9.2 - Customer
--------------------------------------------------------------*/

/*--------------------------------------------------------------
## 9.3 - Tickets
--------------------------------------------------------------*/

.mat-card-content {
  //padding: 2px 12px;
  margin-top: 1rem;
}

.ticket-key {
  color: #27aacc !important;

  mat-icon {
    opacity: 0.5;
  }
}

.status {
  font-weight: bold;

  &.new {
    background-color: #e3342f;
  }

  &.new2 {
    background-color: #ea7b7b;
  }

  &.iProgress {
    background-color: #039be5;
  }

  &.canceld {
    background-color: #FF552F;
  }

  &.done {
    background-color: #5db937;
  }

  &.unsuccessful {
    background-color: #e3342f;
  }

  &.archived {
    background-color: #000000;
  }
}

.status-cell {
  padding: 4px 0 !important;
}

.toggle-view-button {
  background: #35a69e !important;
}

.priority-low {
  color: rgb(89, 186, 70) !important;
}

.priority-medium {
  color: rgb(254, 197, 14) !important;
}

.priority-high {
  color: rgb(243, 115, 34) !important;
}

.priority-critical {
  color: rgb(239, 40, 36) !important;
}

.priority-info {
  color: #333;
}

.ticket-card-list-viewport {
  > .cdk-virtual-scroll-content-wrapper {
    min-height: 100%;
  }
}

.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  min-width: 100%;
  display: grid;
}

omt-ticket-card {
  i {
    cursor: pointer;
  }
}

omt-board-select-row {
  width: 100%;
}

.current-ticket-wrapper {
  height: 100%;

  > .current-ticket-container {
    width: 100%;
    height: 100%;
  }
}

@-moz-document url-prefix() {
  .mat-datepicker-toggle {
    .mat-icon-button {
      display: inline !important;
    }
  }
}

li > a.mention-item {
  width: 100%;
  cursor: pointer;
}

/*--------------------------------------------------------------
## 9.4 - Warenwirtschaft-Artikel
--------------------------------------------------------------*/

.default-price-group {
  font-weight: bolder;
  color: $orange;
}

.origin {
  font-size: 13px;
  padding: 3px;
  border-radius: 2px;
  cursor: pointer;
}

.origin:hover {
  opacity: 0.9;
}

.origin-order {
  background-color: rgba(255, 69, 0, 0.6);
}

.origin-receipt {
  background-color: rgba(25, 25, 112, 0.6);
}

.origin-customer {
  background-color: rgba(87, 158, 36, 0.6);
}

.origin-manual {
  background-color: rgba(0, 255, 126, 0.6)
}

.origin-storage {
  background-color: rgba(255, 235, 59, 0.6);
}

.origin-ticket {
  background-color: rgba(16, 120, 145, 0.6);
}

.origin-inventory {
  background: rgba(35, 199, 236, 0.6);
}

/*--------------------------------------------------------------
## 9.5 - Warenwirtschaft-Verkauf
--------------------------------------------------------------*/

.sales {
  background: #fff !important;
  padding-top: 0.5rem;
}

.sales-section {
  padding: 2rem;
  border-bottom: 1px dashed #eaeaea;

  .sales-heading {
    background: #ababab;
    padding: 2px;
    color: #f2fcf9;
    font-variant: all-small-caps;
    font-weight: bolder;
    width: fit-content;
    margin-bottom: 1rem;
  }

  textarea {
    min-height: 160px;
  }
}

.sales-invalid {
  background: #ffe5e5;
  border-color: #ff4343;
}

.sales-label {
  font-weight: bolder;
}

.required {
  color: #6fa1e3;
  font-size: 11px;
}

.sales-icon {
  color: #6fa1e3 !important;
  cursor: pointer;
}

.toggle-button {
  cursor: pointer;
  padding: 6px 8px;
  line-height: 1.0;
  border: 1px solid $gray;
  font-variant: all-small-caps;
  font-size: 16px;
  font-weight: 800;
  color: $gray;
  font-family: 'Mulish', 'Helvetica Neue', 'Arial', sans-serif;
  transition: all 0.2s ease-in;
  box-shadow: 0 1px 1px -2px rgba(0, 0, 0, 0.2), 0 -1px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 4px 0 rgba(0, 0, 0, 0.12);

  &.active, &:hover {
    color: #6fa1e3;
    background: transparent;
    border-color: #6fa1e3;
  }
}

.sales-options {
  color: #6fa1e3;
  cursor: pointer;
}

.sales-sum {
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px dotted #a0a0a0;
}

.sales-full-sum {
  font-weight: bolder;
}

.sales-header {
  padding-left: 3rem;
  padding-right: 3rem;
  min-height: 65px;
  background: #fcfcfc;
  border-bottom: 2px solid #e3e3e3;
}

.sales-button {
  cursor: pointer;
  background: #f3f3f3;
  height: 36px;
  border-radius: 2px;
  border: 1px solid #e5e5e5;
  padding-left: 2rem;
  padding-right: 2rem;

  &.save {
    background: #f33e30;
    color: #fff;
  }
}

.sales-button:disabled {
  background: #dcdcdc;
  cursor: not-allowed;
}

/*--------------------------------------------------------------
## 9.6 Warenwirtschaft-Lager
--------------------------------------------------------------*/

.storage-list-item {
  > .mat-list-item-content {
    width: 100%;
  }
}

/*--------------------------------------------------------------
## 9.7 Notifications
--------------------------------------------------------------*/

.notification-container {
  display: block;
  cursor: pointer;

  &:not(:first-child) {
    border-top: 1px solid #e1e1e1;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

/*--------------------------------------------------------------
## 10.0 - Info Box
--------------------------------------------------------------*/

.info-box {
  margin: 1rem !important;
  border-radius: 4px;
  border: 0.5px solid #6fa1e3;
  min-height: 80px;
  overflow: hidden;
}

.info-text {
  margin-bottom: 1rem;
}

.info-box-content {
  margin-top: 2rem;
  padding: 2rem;
}

.info-box-footer {
  cursor: pointer;
  background-color: #e2e2e2;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}


/*--------------------------------------------------------------
## 11.0 - Login & Password Reset
--------------------------------------------------------------*/

#login, #password-reset {
  width: 100%;
  background: url('images/backgrounds/loginbgr.jpg') no-repeat;
  background-size: cover;

  #login-form-wrapper {
    flex: 1 0 auto;
    padding: 32px;

    @include media-breakpoint('xs') {
      padding: 16px;
    }

    #login-form {
      width: 384px;
      max-width: 384px;
      padding: 32px;
      text-align: center;
      background: #ffffff;
      border-radius: 25px;
      @include mat.elevation(16);

      @include media-breakpoint('xs') {
        padding: 24px;
        width: 100%;
      }

      .logo {
        width: 200px;
        margin: auto;
      }

      .title {
        font-size: 20px;
        margin: 16px 0 32px 0;
      }

      form {
        width: 100%;
        text-align: left;

        mat-form-field {
          width: 100%;
        }

        mat-checkbox {
          margin: 0;
        }

        .remember-forgot-password {
          font-size: 13px;
          margin-top: 8px;

          .remember-me {
            margin-bottom: 16px;
          }

          .forgot-password {
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;
            margin-bottom: 16px;
          }
        }
      }

      .submit-button {
        width: 220px;
        margin: 16px auto;
        display: block;

        @include media-breakpoint('xs') {
          width: 90%;
        }
      }

      .register {
        margin: 32px auto 24px auto;
        font-weight: 600;

        .text {
          margin-right: 8px;
        }
      }

      .separator {
        font-size: 15px;
        font-weight: 600;
        margin: 24px auto;
        position: relative;
        overflow: hidden;
        width: 100px;

        .text {
          display: inline-flex;
          position: relative;
          padding: 0 8px;
          z-index: 9999;

          &:before,
          &:after {
            content: '';
            display: block;
            width: 30px;
            position: absolute;
            top: 10px;
            border-top: 1px solid;
          }

          &:before {
            right: 100%;
          }

          &:after {
            left: 100%;
          }
        }
      }

      button {
        &.google,
        &.facebook {
          width: 192px;
          text-transform: none;
          color: #ffffff;
          font-size: 13px;
        }

        @include media-breakpoint('xs') {
          width: 80%;
        }

        &.google {
          background-color: #d73d32;
          margin-bottom: 8px;
        }

        &.facebook {
          background-color: rgb(63, 92, 154);
        }
      }
    }
  }
}

/*--------------------------------------------------------------
## 12.0 Animations
--------------------------------------------------------------*/

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 8px;
  margin-right: 10px;
}

.lds-ellipsis div {
  position: absolute;
  top: 14px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $gray;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: -9px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: -10px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 2px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 24px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.article-dropdown-item {
  font-size: 13px;

  .article-name {

  }

  .article-number {
    background: lightgray;
    padding: 0 4px;
    font-size: 0.8em;
  }
}

.hover-border {
  transition: all 0.5s ease;

  &:hover {
    border: 1px solid $black;
  }
}

#main {
  padding-right: 0 !important;
}
