@use 'colors';

button.mat-primary:not(.mat-button):disabled .mat-button-wrapper {
  color: colors.$white;

  i, span {
    color: colors.$white;
    cursor: default;
  }
}

button .mat-button-wrapper {
  > i {
    color: inherit;
  }
}

.mat-flat-button {
  background-color: #373a3e !important;
  color: colors.$white !important;
  padding: 0 15px !important;
  border: none;
  width: auto;

  border-radius: 4px;
  margin: 10px;
  transition: all 0.4s ease-in-out;

  cursor: pointer;

  i {
    color: colors.$white !important;
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    transition: all 0.4s ease-in-out;

    color: colors.$white !important;
    background-color: #26282b !important;

    &.dashboard {
      background-color: map-get($bgr-colors, 'blue') !important;
    }

    &.tickets {
      background-color: map-get($bgr-colors, 'cyan') !important;
    }

    &.zeit {
      background-color: map-get($bgr-colors, 'lime') !important;
    }

    &.kunden {
      background-color: map-get($bgr-colors, 'green') !important;
    }

    &.personal {
      background-color: map-get($bgr-colors, 'yellow') !important;
    }

    &.waren {
      background-color: map-get($bgr-colors, 'gold') !important;
    }

    &.report {
      background-color: map-get($bgr-colors, 'orange') !important;
      color: #26282b !important;
    }

    &.verwaltung {
      background-color: map-get($bgr-colors, 'red') !important;
    }

    &.einstellung {
      background-color: map-get($bgr-colors, 'winered') !important;
    }
  }

  &:focus {
    background-color: #373a3e;
  }

  &:active {
    background-color: #373a3e;
  }

  &.mat-mini-fab {
    padding: 0 !important;
  }

  &.border-round {
    border-radius: 25px !important;
  }
}

.mat-mini-fab {
  i {
    color: colors.$white !important;
  }

  &:disabled {
    opacity: 0.4;
  }
}

.mat-fab {
  i {
    color: colors.$white !important;
  }

  &:disabled {
    opacity: 0.4;
  }
}

.mat-mini-fab.mat-primary,
.mat-fab.mat-primary {
  background-color: #3d4246 !important;

  i {
    color: colors.$white !important;
  }
}

mat-button-toggle-group {
  input {
    background-color: #26282b !important;
    color: colors.$white !important;
    //max-width: 80px;
  }

  ::placeholder {
    color: colors.$white !important;
  }
}
