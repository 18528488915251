@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(''),
  url('../font/mulish-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/mulish-v12-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../font/mulish-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../font/mulish-v12-latin-300.svg#Mulish') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
  url('../font/mulish-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/mulish-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../font/mulish-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../font/mulish-v12-latin-regular.svg#Mulish') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
  url('../font/mulish-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/mulish-v12-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../font/mulish-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../font/mulish-v12-latin-600.svg#Mulish') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
  url('../font/mulish-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/mulish-v12-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../font/mulish-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../font/mulish-v12-latin-700.svg#Mulish') format('svg'); /* Legacy iOS */
}
