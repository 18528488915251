@import "colors";
@import '../fuse/scss/fuse';

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 - Ng-Select
    1.1 - Entity Dropdown (customer-dropdown, project-dropdown, ..)
    1.2 - Sales Specific
    1.3 - Ticket Dropdown
    1.4 - General
2.0 - Text-Classes
    2.1 - Section Header
3.0 - Search-Field
4.0 - Material Form Components
    4.1 - Form Field
    4.2 - Slide-Toggle
    4.3 - Tab-Group
    4.4 - Group Wrapper
5.0 - Inputs
    5.1 - Normalization
    5.2 - Types
    5.3 - Increment Group
    5.4 - Input + Label
    5.5 - Validation
6.0 - Disabled Inputs
*/

$input-border-color: #acacb6;
$disabled-color: #D6D6D6;

// -----------------------------------------------------------------------------------------------------
// 1.0 Ng-Select
// -----------------------------------------------------------------------------------------------------

// ---------------------------------------------------------------
// 1.1 Entity Dropdown
// ---------------------------------------------------------------

.ng-select.entity-dropdown {
  width: inherit !important;
}

@include media-breakpoint('lt-md') {
  .ng-select.entity-dropdown {
    min-width: 90px;
  }
}

.ng-dropdown-panel.entity-dropdown > .ng-dropdown-footer {
  padding: 0;
}

// ---------------------------------------------------------------
// 1.2 Sales Specific
// ---------------------------------------------------------------

.sales-input > .ng-select-container, .sales-input.ng-select-opened > .ng-select-container {
  border: unset;
  background: transparent !important;
}

.sales-input > .ng-select-container:hover {
  box-shadow: unset;
}

.sales-input > .ng-select-container {
  box-shadow: unset !important;
  border-color: transparent !important;
  height: 40px !important;
  border-radius: unset;
}

.sales-input > .ng-placeholder {
  font-size: 14px !important;
}

.sales-input > .ng-dropdown-panel {
  width: calc(100% + 2px);
  left: -1px;
}

.sales-input > .ng-option {
  padding: 12px 10px;
}

.sales-input > .ng-select-container, .sales-input.ng-select-opened > .ng-select-container {
  border: unset;
  background: transparent !important;
}

.sales-input > .ng-select-container:hover {
  box-shadow: unset;
}

.sales-input > .ng-select-container {
  box-shadow: unset !important;
  border-color: transparent !important;
  height: 40px !important;
}

.sales-input > .ng-placeholder {
  font-size: 14px !important;
}

omt-date-picker.sales-input > .mat-form-field.redesign {
  border: unset;
}

// ---------------------------------------------------------------
// 1.3 Ticket Dropdown
// ---------------------------------------------------------------

// TICKET DROPDOWN
.current-ticket-container {
  .ng-dropdown-panel, .ng-dropdown-panel-items {
    max-height: 80vh !important;
    overflow: hidden;
  }

  .ng-clear-wrapper {
    display: none;
  }


  .ng-select .ng-select-container:hover {
    box-shadow: unset !important;
  }

  .ng-select .ng-select-container {
    border: none;
    background-color: transparent;
  }

  .ng-select-focused {
    outline: none !important;
  }

  .ng-select {
    outline: none !important;
  }

}

// ---------------------------------------------------------------
// 1.4 General
// ---------------------------------------------------------------

.ng-select {
  min-width: 140px;
}

.ng-select.ng-invalid.ng-touched .ng-placeholder {
  color: #f44336 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  position: static;
}

.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper,
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper {
  z-index: 5001;
}

// -----------------------------------------------------------------------------------------------------
// 2.0 Text-Classes
// -----------------------------------------------------------------------------------------------------

// ---------------------------------------------------------------
// 2.1 Section Header
// ---------------------------------------------------------------

.section-header {
  color: #4a475e;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}

.section-header {
  &.time-tracking {
    background-color: #158461;
    padding: 1.2rem 0 1.2rem 3rem;
  }
}

#personal {
  .section-header {
    background-color: #fdf3bf;
    padding: 1.2rem 0 1.2rem 3rem;
  }
}

#customer {
  .section-header {
    background-color: rgb(213, 231, 200);
    padding: 1.2rem 0 1.2rem 3rem;
  }
}

#warehouse {
  .section-header {
    background-color: rgb(251, 232, 194);
    padding: 1.2rem 3rem 1.2rem 3rem;
  }
}

#tickets {
  .section-header {
    // original
    //background-color: #cae1e7;
    background-color: #37888f;
    //background-color: #454d63;
    color: #fff;
    // alternative? background-color: #107891;
    padding: 1.2rem 0 1.2rem 3rem;

    i {
      color: #fff;
    }
  }
}

#project-management {
  .section-header {
    background-color: rgb(236, 239, 201);
    padding: 1.2rem 0 1.2rem 3rem;
  }
}

// -----------------------------------------------------------------------------------------------------
// 3.0 Search-Field
// -----------------------------------------------------------------------------------------------------


.search-field {
  position: relative;
  margin-top: -5px;

  .search-field-input {
    background: #fafafa;
    width: 100%;
    height: 36px;

    padding-left: 40px;
    border: solid 1px #cacaca;
    border-radius: 25px;
    color: #332f35;
    outline: none;

    &:focus {
      border-color: #332f35;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05); // shadow(1);
    }
  }

  .search-field-icon {
    position: absolute;
    top: 5px;
    left: 8px;
    color: #332f35;
  }
}

.mat-card.rounded {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;

  .section-header:first-of-type {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
}

// -----------------------------------------------------------------------------------------------------
// 4.0 Material Form Components
// -----------------------------------------------------------------------------------------------------

// ---------------------------------------------------------------
// 4.1 Form-Field
// ---------------------------------------------------------------

.mat-form-field {
  &.editable {
    .mat-form-field-infix {
      background: #ffffe9;
      border-radius: 4px;
    }
  }
}

omt-date-picker.sales-picker .mat-form-field, .mat-form-field.sales-form-field {
  border: 1px solid $gray;
  padding: 12px;
  min-width: 200px;
  max-height: 42px;
  border-radius: 0;
}

.mat-form-field.redesign {
  max-height: 40px;
  border: 0.5px solid #acacb6;
  border-radius: 4px;
  padding: 10px 16px;
}

.omt-datepicker .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 2em;
}

.mat-form-field.form-field-unset {
  .mat-form-field-infix .mat-input-element {
    padding-left: unset !important;
    font-size: 14px;
  }

  .mat-form-field-suffix {
    top: 0.4em !important;
  }

  .mat-form-field-outline {
    display: none !important;
  }

  .mat-form-field-wrapper {
    padding: unset !important;
    margin: unset !important
  }

  .mat-form-field-flex {
    padding: unset !important;
    margin-top: -5em !important;
  }
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-left: 0 !important;
}

.mat-form-field-infix {
  .mat-input-element {
    padding-left: 0.5em !important;
  }
}

.head-background,
omt-header {
  .ng-value-container {
    // only with material theme
    //padding-top: 5px;
  }

  .ng-dropdown-panel {
    top: 27px;
    border: solid 1px #dadada;
  }

  .ng-clear-wrapper {
    margin-left: 5px !important;
  }
}

// ---------------------------------------------------------------
// 4.2 Slide-Toggle
// ---------------------------------------------------------------

body.theme-default .mat-slide-toggle.colored {
  .mat-slide-toggle-bar {
    background-color: rgb(221 35 35 / 48%);
  }

  .mat-slide-toggle-thumb {
    background-color: #d0482e;
  }

  &.mat-checked .mat-slide-toggle-bar {
    background-color: rgb(10 193 49 / 48%);
  }

  &.mat-checked .mat-slide-toggle-thumb {
    background-color: #73bb89;
  }
}

body.theme-default .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(60, 66, 82, 0.54)
}

body.theme-default .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #3c4252;
}

// ---------------------------------------------------------------
// 4.3 Tab-Group
// ---------------------------------------------------------------


.mat-tab-group.p-0 > .mat-tab-body-wrapper .mat-tab-body-content {
  padding: 0 !important;
}

// ---------------------------------------------------------------
// 4.4 Group Wrapper
// ---------------------------------------------------------------

.mat-group-wrapper {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  background-color: #373a3e !important;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;

  label {
    color: #fafafa !important;
  }

  .mat-radio-inner-circle {
    background-color: #fafafa !important;
  }
}

.mat-group-wrapper:hover {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  color: #fafafa !important;
  background-color: #26282b !important;
}

// ---------------------------------------------------------------
// 4.5 Radio Group
// ---------------------------------------------------------------

body.theme-default .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgb(162 162 162);
}

.mat-radio-group {
  @include media-breakpoint('xs') {
    display: flex;
    flex-direction: column;
  }
}

// -----------------------------------------------------------------------------------------------------
// 5.0 Inputs
// -----------------------------------------------------------------------------------------------------

// ---------------------------------------------------------------
// 5.1 Normalization
// ---------------------------------------------------------------

// placeholder position
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 10px;
  padding-left: 9px;
}

.ng-placeholder, .ng-value-label {
  padding-left: 4px;
}

// placeholders for material inputs and ng-select
.mat-label, .ng-placeholder {
  font-size: 14px;
  color: #757575 !important;
}

// underline / 'border-bottom' for material form fields
.mat-form-field-underline {
  background-color: $input-border-color !important;
}

.form-group {
  align-items: center;
}

textarea.form-control {
  min-height: 90px;
}

// was used for normalization after implementing the fuse theme,
// however, it messes up material / ng-select inputs

input[type='text'],
input[type='number'],
input[type='time'],
input[type='password'] {
  min-height: 34px;
}

input[disabled] {
  min-height: 34px;
}

.ng-input > input[type='text'] {
  min-height: unset !important;
  padding-left: 4px !important;
}

.mat-input-element :not(.cdk-textarea-autosize) {
  min-height: unset !important;
}

// ---------------------------------------------------------------
// 5.2 Types
// ---------------------------------------------------------------


@include media-breakpoint('xs') {
  .input-wrapper:after {
    display: none;
  }

  .input-wrapper > input {
    left: 0 !important;
  }
}

.input-wrapper {
  position: relative;
  width: fit-content;
  width: -moz-fit-content;
  padding: 0;

  > input {
    position: relative;
    width: 100%;
  }
}

.input-wrapper:after {
  content: attr(data-symbol);
  position: absolute;
  right: 15px;
  top: 12px;
  font-size: 12px;
  color: #acacb6 !important;
  pointer-events: none;
}

.input-wrapper > input[type=number]::-webkit-inner-spin-button, .input-wrapper > input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-wrapper > input[type=number] {
  -moz-appearance: textfield;
}

.sales-input {
  border: 1px solid $gray;
  min-width: 160px;
}

input.sales-input {
  padding: 12px;
}

.sales-input > .ng-dropdown-panel {
  width: calc(100% + 2px);
  left: -1px;
}

// ---------------------------------------------------------------
// 5.3 Increment Group
// ---------------------------------------------------------------

.increment-group > button {
  background-color: #acacb6;
  border: none;
  color: #fff;
  padding: 8px 15px;
  cursor: pointer;
  font-weight: bolder;
  height: 100%;
  font-size: 14px !important;
}

td > omt-increment-group > .increment-group > button {
  font-size: 13px !important;
}

.increment-group > input {

  border: 1px solid $gray;


  padding: 12px;

  height: 100%;
  max-width: 120px;
}

@include media-breakpoint('xs') {
  .increment-group > input {
    width: 60px;
  }
}

// ---------------------------------------------------------------
// 5.4 Input + Label
// ---------------------------------------------------------------

// restyle? low contrast makes it hard to use currently
label.detail-label {
  color: #353a48;
  font-weight: lighter;
  font-size: 13px;

  // alternative?
  //font-weight: lighter;
  //font-size: 14px;
  //font-variant: all-petite-caps;
  //letter-spacing: 1.6px;
}

$labelInputMargin: -5px;

:not(.is-row) > {
  label + * {
    margin-top: $labelInputMargin;
  }
}

// ---------------------------------------------------------------
// 5.5 - Validation
// ---------------------------------------------------------------

.is-invalid, input.redesign.ng-invalid:not(.ng-pristine), textarea.redesign.ng-invalid:not(.ng-pristine) {
  border-color: #dc3545 !important;
}

.invalid-feedback {
  display: block;
  font-size: 13px;
}

// ---------------------------------------------------------------
// 6.0 - Disabled Inputs
// ---------------------------------------------------------------

input:disabled, input:read-only {
  background-color: #e2e2e2;

}

input.redesign:disabled, input.redesign:read-only {
  border-color: $disabled-color;
  color: #676767;
}

.ng-select-disabled > .ng-select-container {
  border-color: $disabled-color !important;
}

.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder {
  color: $disabled-color !important;
}
