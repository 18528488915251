.dark-active {
  background-color: #373a3e !important;
}

/*--------------------------------------------------------------
  Colors
--------------------------------------------------------------*/
$text-color: #4a475e;

//$black: #26282b; // old
$white: #fafafa;
$black: #3d4246;
$blue: #1553ae;
$cyan: #107891;
$lime: #158461;
$green: #579e24;
$yellow: #f8ce00;
$gold: #efa40c;
$orange: #ed6a1d;
$red: #d53223;
$winered: #9e1a3e;
$f-muted: #acacb6;
$gray: #c2c2c2;

$font-color: (
  'text-light': #fafafa,
  'text-dark': #26282b,
  'text-gray': #a5a5a5
);

$bgr-colors: (
  'blue': #1553ae,
  'cyan': #107891,
  'lime': #158461,
  'green': #579e24,
  'yellow': #f8ce00,
  'gold': #efa40c,
  'orange': #ed6a1d,
  'red': #d53223,
  'winered': #9e1a3e,
  // Gradients
  'blue-cyan': linear-gradient(to right, #1553ae, #0060ad, #006aa6, #00729c, #107891),
  'cyan-lime': linear-gradient(to right, #107891, #007c8b, #008081, #008272, #158461),
  'lime-green': linear-gradient(to right, #158461, #1d8b58, #2e924b, #41993a, #579e24),
  //'green-yellow': linear-gradient(to right, #579e24, #76a81b, #95b012, #b4b80c, #d4bf10),
  'green-yellow': linear-gradient(to right, #3ca335, #b3b312),
  'green-yellow-second': linear-gradient(to right, #bcd647, #ffe910),
  'yellow-gold': linear-gradient(to right, #f8ce00, #dbb908, #e2b204, #e9ab06, #efa40c),
  'gold-orange': linear-gradient(to right, #efa40c, #f0960c, #f08810, #ef7916, #ed6a1d),
  'orange-red': linear-gradient(to right, #ed6a1d, #e85d1e, #e25020, #dc4221, #d53223),
  'red-winered': linear-gradient(to right, #d53223, #c9282d, #bc2135, #ad1c3a, #9e1a3e),
  'winered-blue': linear-gradient(to right, #9e1a3e, #9b1c5f, #892e80, #66429c, #1553ae)
);

/*--------------------------------------------------------------
Text Colors
--------------------------------------------------------------*/

.text-main {
  color: $text-color !important;
}

.text-red {
  color: $red !important;
}

.text-black {
  color: $black !important;
}

.text-orange {
  color: $orange !important;
}

.text-yellow {
  color: $yellow !important;
}

.text-white {
  color: $white !important;
}

.text-blue {
  color: $blue !important;
}

.text-cyan {
  color: $cyan !important;
}

.text-green {
  color: $green !important;
}

.text-lime {
  color: $lime !important;
}

.text-muted {
  color: $f-muted !important;
  font-size: 12px;
}

.text-hint {
  color: #757575 !important;
  font-size: 11px;
}

/*--------------------------------------------------------------
Background Colors
--------------------------------------------------------------*/

.background-red {
  background-color: $red !important;
}

.background-orange {
  background-color: $orange;
}

.background-black {
  background-color: $black !important;
}

.bg-cyan {
  background-color: $cyan !important;
}
