@import '../fuse/scss/fuse';

// TODO Refactor - use mixins

/*--------------------------------------------------------------
# 3.0 - Helper Classes
--------------------------------------------------------------*/

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.img-dashed-border {
  padding: 1.5rem; /* added */
  border: 1px dashed rgb(235, 235, 235); /* added */
  border-radius: 4px; /* added */
}

.td-max-200 {
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.media-object {
  height: 250px !important;
  width: 250px !important;

  @include media-breakpoint('lg') {
    height: 200px !important;
    width: 200px !important;
  }
}

.text-align-right {
  text-align: right;
}

.subtitle {
  margin-top: -0.775rem;
  margin-bottom: 0;
  font-size: 11px;
}

.small-text {
  font-size: 11px;
}

.display-none {
  display: none;
}

.border-top {
  border-top: solid 2px #26282b;
}

.bs-unset {
  box-shadow: none !important;
}

.pull-left {
  float: left;
}

i.fa-1-5x {
  font-size: 1.5em !important;
  width: auto;
  height: auto;
  line-height: normal;
}

i.fa-2x {
  font-size: 2em !important;
  width: auto;
  height: auto;
  line-height: normal;
}

i.fa-3x {
  font-size: 3em !important;
  width: auto;
  height: auto;
  line-height: normal;
}

i.fa-5x {
  font-size: 5em !important;
  width: auto;
  height: auto;
  line-height: normal;
}

.m-vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}

.v-a-middle {
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle;
}

.of-scroll {
  overflow: scroll;
}

.of-hidden {
  overflow: hidden;
}

.of-visible {
  overflow: visible !important;
}

.mat-dialog-overflow .mat-dialog-container {
  overflow: visible !important;
}

.floating-button {
  position: absolute !important;
  right: 50px;
  z-index: 999999;
  top: -21px;
}

.position-mid {
  position: absolute !important;
  left: calc(50% - 100px) !important;
  right: calc(50% - 100px) !important;
}

.vh-10 {
  min-height: 10vh;
}

.vh-20 {
  min-height: 20vh;
}

.vh-30 {
  min-height: 30vh;
}

.vh-40 {
  min-height: 40vh;
}

.vh-50 {
  min-height: 50vh;
}

.vh-60 {
  min-height: 60vh;
}

.vh-70 {
  min-height: 70vh;
}

.vh-80 {
  min-height: 80vh;
}

.vh-90 {
  min-height: 90vh;
}

.invert {
  filter: invert(1);
}

/*--------------------------------------------------------------
## 3.1 - Width
--------------------------------------------------------------*/

@mixin width-helpers($width) {
  $targetWidth: $width * 10;

  .w-#{$targetWidth} {
    width: #{$targetWidth}px !important;
  }

  .m-w-#{$targetWidth} {
    min-width: #{$targetWidth}px !important;
  }
}

@for $size from 1 through 50 {
  @include width-helpers($size);
}

.m-w-unset {
  min-width: unset !important;
}


/*--------------------------------------------------------------
## 3.2 - Height
--------------------------------------------------------------*/

.h-100-p {
  height: 100%;
}

.h-fit-c {
  height: fit-content;
}

.m-h-unset {
  min-height: unset !important;
}

/*--------------------------------------------------------------
## 3.3 - Margin
--------------------------------------------------------------*/

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.ml-3 {
  margin-left: 3rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.m-tb-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.m-lr-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

/*--------------------------------------------------------------
## 3.4 - Padding
--------------------------------------------------------------*/

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 1rem !important;
}

.p-2 {
  padding: 1.5rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.p-4 {
  padding: 4rem !important;
}

.p-5 {
  padding: 5rem !important;
}

.p-10 {
  padding: 10px !important;
}

.pl-2 {
  padding-left: 1.5rem !important;
}

.pr-2 {
  padding-right: 1.5rem !important;
}

.pl-3 {
  padding-left: 3rem !important;
}

.pr-3 {
  padding-right: 3rem !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

/*--------------------------------------------------------------
## 3.5 - Border
--------------------------------------------------------------*/

.border-bottom-grey {
  border-bottom: 1px solid #d9d9d9;
}

.border-none {
  border: none !important;
}

.border-radius-50 {
  border-radius: 50%;
}

.br-20 {
  border-radius: 20px !important;
}

.br-0 {
  border-radius: 0 !important;

  .mat-dialog-container {
    border-radius: 0 !important;
  }
}

@mixin section-marker($color) {
  position: relative;
  padding-left: 14px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 8px;
    height: 100%;
    background-color: $color;
    border-radius: 4px;
  }
}
